@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --color-primary: #ff6600;
    --color-secondary: 111 114 185;
    /* ... */
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont,  'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  height: 100%;

overflow-y: scroll;
-webkit-overflow-scrolling: touch;
}
#root{
  touch-action: pan-x pan-y;
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.h-screen {
 
  /* height: calc(var(--vh, 1vh) * 100); */
}
img {
  pointer-events: none;
  user-select: none;
}
html {
  overflow: hidden;
width: 100%;
}
#mobilBody{
  transition: all 0.4s  cubic-bezier(0.075, 0.82, 0.165, 1);
  
}
#sheetBody{
  transition: all 0.2s  cubic-bezier(0.075, 0.82, 0.165, 1);
  
}
#myElement13{
  transition: all 0.4s  cubic-bezier(0.075, 0.82, 0.165, 1);
}
.mobilSmallBody{
  
  scale: 0.9;
  transform: translateX(250px);
  border-radius: 1rem;
}
.bottomsheet{
  transform: scaleY(0);
  position: relative !important;

  
}
.bottomsheet :first{
}
.mobilWebBody{
  
  scale: 0.9;
  transform: translate(250px,10px);
  border-radius: 1rem;
}

.filterscroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.filterscroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
@media (min-width: 1024px) {
  .stickyHeader{
    
      background-color: rgba(255, 255, 255, 0.7);
    --tw-backdrop-blur: blur(2rem/* 32px */);
      backdrop-filter: var(--tw-backdrop-blur);
      box-shadow: 0rem 2rem 1.5rem -0.5rem rgba(0, 0, 0, 0.13);
      border-radius: 2rem;
      background-color: #ff6600;
      
  }
}
