.vanabut  {
    
    color: black;
  }
  
  .vanabut input:checked {
    background-color: #22c55e; /* bg-green-500 */
  }
  
  .vanabut input:checked ~ span:last-child {
    --tw-translate-x: 3rem; /* translate-x-7 */
  }
  .customTimePicker fieldset {
    border: none;
  }

  .customTimePicker input {
    font-size: 3rem ;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont,  'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
    color: #454A73;

    padding: 0rem;

  }

  .customTimePicker {
    
    width:  9rem;
  
    
  }