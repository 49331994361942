.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-modal-sheet-drag-indicator {
  background-color: #454A73 !important; /* !important kullanımı bazen gerekli olabilir */
  height: 3px !important;
  width: 1.5rem !important;
}
.leaflet-popup-content p {
margin: 0 !important;
}


.leaflet-popup-content-wrapper , .leaflet-popup-content{
  /* transform: translate3d() scale(0.7) !important; */
  margin: 0 !important;
  background: none !important;
  box-shadow: none !important;
}


.leaflet-popup-content{
  width: 100% !important;
}
.leaflet-popup-tip-container{
  opacity: 0 !important;
}
.leaflet-popup-close-button{
  display: none !important;
  margin-left: -1rem !important;

}
